import styled from 'styled-components'
import { rounded } from 'src/styles/rounded'
import { orange, grayscale, white } from 'src/styles/colors'

export const ContentPortability = styled.div`
  cursor: pointer;
  border: 1px solid ${grayscale['200']};

  &.active {
    border: 1px solid ${orange.extra};
  }

  span.select {
    background: ${grayscale[100]};
    border-radius: 8px;
    display: block;
    position: relative;
    outline: none;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      right: 21px;
      top: 17px;
      color: ${orange.extra}
    }
    select {
      appearance: none;
      background: ${grayscale[100]}; 
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      padding: 0 16px;
      width: 100%;
      height: 48px;
      color:${grayscale[500]};
      white-space: nowrap;

      :focus{
        outline:none;
      }
    }
  } 

  p {
    word-break: break-word;
  }
`

export const Btn = styled.button`
  background-color: transparent;
  border: none;
  text-align: left;
 
  &:focus { 
    outline: 0;
  }

  .circle {
    border: 2px solid ${grayscale['300']};
    width: 24px; 
    height: 24px;
    float: left;
    border-radius: ${rounded['5']};

    &.active {
      background-color: ${orange.extra};
      border: 2px solid ${orange.extra};
    }
    &.inactive {
      background-color: transparent;
      border: 2px solid ${grayscale['300']};
    }

    span {
      background-color: ${orange.extra};
      border: 3px solid ${white};
      width: 18px;
      margin: 1px 0 0 1px;
      height: 18px;
      float: left;
      border-radius: 16px;
    }
  }
`
