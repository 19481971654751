/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, ChangeEvent } from 'react'

// Hooks
import useWidth from 'src/hooks/window/useWidth'

// Data
import FundPortabilityJSON from './FundPortability.json'

// Styles
import { ContentPortability, Btn } from './style'

type FundPortabilityProps = {
  title: string;
  description: string;
}

const WIDTH_MD = 768

const FundPortability = () => {
  const [ content, setContent ] = useState(0)
  const [ isFundos, setFundos ] = useState('XP Investimentos')
  const width = useWidth(300)

    return (
      width < WIDTH_MD
        ? <>
          <ContentPortability
            className='d-flex flex-column active rounded-3 mt-4 px-3 py-2'
          >
            <p className='fs-14 lh-17 text-grayscale--400 pt-2 mb-1'>Selecione sua corretora de origem</p>
            <span className='select mb-2'>
              <select
                name={isFundos}
                id='fundos'
                onChange={(event: ChangeEvent<HTMLSelectElement>) => setFundos(event.currentTarget.value)}
              >
                {FundPortabilityJSON.map((item: FundPortabilityProps, index: number) => (
                  <option value={item.title} key={index}>{item.title}</option>
              ))}
              </select>
            </span>
            {FundPortabilityJSON.map((item: FundPortabilityProps, index: number) => (
            isFundos === item.title &&
              <div className='border rounded-3 px-3 py-2 my-2' key={index}>
                <p className='fs-14 lh-17 text-grayscale--400 mb-0'>{item.description}</p>
              </div>
          ))}
          </ContentPortability>
        </>
        : <>
          { FundPortabilityJSON.map((item: FundPortabilityProps, index: number) => (
            <ContentPortability
              key={index} onClick={() => setContent(index)}
              className={`d-flex flex-column rounded-3 px-3 py-2 mb-3 ${content === index ? 'active' : ''}`}
            >
              <Btn className='fs-14 text-grayscale--500 fw-600 my-2'>
                <span className={`${content === index ? 'active' : ''} circle mr-3`}>
                  <span className={`${content !== index && 'bg-white'}`} />
                </span>
                {item.title}
              </Btn>
              {content === index &&
                <div className='fs-14 border rounded-3 px-3 py-2 my-2'>
                  <p className='text-grayscale--400 mb-0'>
                    {item.description}
                  </p>
                </div>
            }
            </ContentPortability>
        ))}
        </>
  )
}

export default FundPortability
