import React from 'react'
import Img from 'gatsby-image'

import ScrollTo from 'src/components/ScrollTo'
import Breadcrumb from 'src/components/Breadcrumb'

import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'

import { HeroSection } from './style'

const WIDTH_MD = 768

const Fundos = () => {
  const data = usePageQuery()
  const windowWidth = useWidth(300)

  return (
    <HeroSection className='py-5 bg-gray d-md-flex position-relative align-items-star align-items-md-center'>
      <div className='container pt-lg-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6'>
            {windowWidth <= WIDTH_MD
              ? <Img fluid={data.seusFundosRendemMaisMobile.fluid} alt='Imagem que representa Fundos do Inter' />
              : <Img fluid={data.seusFundosRendemMaisDesktop.fluid} alt='Imagem que representa Fundos do Inter' className='float-md-left' />
            }
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            {windowWidth >= WIDTH_MD &&
              <Breadcrumb
                sectionName='Seus fundos rendem mais aqui no Inter!'
                type='pra você'
                text='Fundos de Investimento'
                link='/pra-voce/investimentos/fundos-de-investimento/'
                styles='mb-2'
              />}
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mt-3 mt-md-0 text-grayscale--500'>
              <span className='d-xl-block'>Seus fundos rendem</span> mais aqui no Inter!
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 mt-3 text-grayscale--500'>
              Faça a <strong>portabilidade dos seus fundos</strong> para o Inter e turbine sua rentabilidade com nosso <strong>cashback de fundos</strong> para clientes <strong>One, Black e WIN.</strong>
            </p>
            <ScrollTo
              to='#passo-a-passo'
              section='dobra_01'
              sectionName='Seus fundos rendem mais aqui no Inter!'
              elementName='Quero trazer meus fundos para o Inter'
              styles='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none mt-2'
            >
              Quero trazer meus fundos para o Inter
            </ScrollTo>
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

export default Fundos
