import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, grayscale } from 'src/styles/colors'

export const HeroSection = styled.section`
  min-height: calc(100vh - 64px);
  
  @media ${device.tablet} {
    min-height: 450px;
  }
  @media ${device.desktopLG} {
    min-height: 700px;
  }

  .bread-crumb {
    span {
      color: ${grayscale['500']} !important;
      opacity: 1; 

    a {
      color: ${grayscale['500']};
      font-weight: 600;

      &:hover {
        color: ${orange.dark};

          @media ${device.tablet} {
            color: ${grayscale['200']};
          }
        }
      }
    }
  }
`
