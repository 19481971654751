import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      seusFundosRendemMaisMobile: imageSharp(fluid: { originalName: { regex: "/seus-fundos-rendem-mais-mobile/" }}) {
        fluid(maxWidth: 336, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seusFundosRendemMaisDesktop: imageSharp(fluid: { originalName: { regex: "/seus-fundos-rendem-mais-desktop/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      FundoDisponivel: imageSharp(fluid: { originalName: { regex: "/fundo-disponivel/" }}) {
        fluid(maxWidth: 456, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      PerfilInvestidor: imageSharp(fluid: { originalName: { regex: "/perfil-investidor/" }}) {
        fluid(maxWidth: 456, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      ChatBabiFundos: imageSharp(fluid: { originalName: { regex: "/chat-babi-fundos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      CartaPortabilidadeMobile: imageSharp(fluid: { originalName: { regex: "/carta-portabilidade-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BgVideoCartaPortabilidadeDesktop: imageSharp(fluid: { originalName: { regex: "/bg-video-carta-portabilidade-desktop/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      Andamento: imageSharp(fluid: { originalName: { regex: "/andamento/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      Cashback: imageSharp(fluid: { originalName: { regex: "/cashback-no-app/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      SeusFundosInter: imageSharp(fluid: { originalName: { regex: "/seus-fundos-com-o-inter/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
