import React from 'react'

import { Wrapper } from './style'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Fundos from './sections/hero/_index'
import PassoAPasso from './sections/passo-a-passo/_index'
import Atencao from './sections/atencao/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const EmprestimoConsignadoPublico = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Fundos />
        <PassoAPasso />
        <Atencao sendDatalayerEvent={sendDatalayerEvent} />
      </Layout>
    </Wrapper>
  )
}

export default EmprestimoConsignadoPublico
